/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { MonetizationPlanForecastDto } from '../models/MonetizationPlanForecastDto';
import type { PXCommandResult } from '../models/PXCommandResult';
import type { UpdateForecastCommand } from '../models/UpdateForecastCommand';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class MonetizationPlanForecastsService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * @param planId
     * @returns MonetizationPlanForecastDto OK
     * @throws ApiError
     */
    public getApiMonetizationPlansForecast(
        planId: number,
    ): CancelablePromise<MonetizationPlanForecastDto> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/monetization/plans/{PlanId}/forecast',
            path: {
                'PlanId': planId,
            },
        });
    }
    /**
     * @param planId
     * @param requestBody
     * @returns PXCommandResult OK
     * @throws ApiError
     */
    public patchApiMonetizationPlansForecast(
        planId: string,
        requestBody?: UpdateForecastCommand,
    ): CancelablePromise<PXCommandResult> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/api/monetization/plans/{PlanId}/forecast',
            path: {
                'PlanId': planId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
