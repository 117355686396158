/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GetMarketplaceAdGroupCreativesQuery } from '../models/GetMarketplaceAdGroupCreativesQuery';
import type { GetMarketplaceAdGroupCreativesQueryResult } from '../models/GetMarketplaceAdGroupCreativesQueryResult';
import type { GetMarketplaceAdGroupsQuery } from '../models/GetMarketplaceAdGroupsQuery';
import type { GetMarketplaceAdGroupsQueryResult } from '../models/GetMarketplaceAdGroupsQueryResult';
import type { PXCommandResult } from '../models/PXCommandResult';
import type { ToggleMarketplaceAdGroupCreativeStatusCommand } from '../models/ToggleMarketplaceAdGroupCreativeStatusCommand';
import type { ToggleMarketplaceAdGroupStatusCommand } from '../models/ToggleMarketplaceAdGroupStatusCommand';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class MarketplaceService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * @param requestBody
     * @returns GetMarketplaceAdGroupsQueryResult OK
     * @throws ApiError
     */
    public postApiMonetizationMarketplaceAdgroups(
        requestBody?: GetMarketplaceAdGroupsQuery,
    ): CancelablePromise<GetMarketplaceAdGroupsQueryResult> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/monetization/marketplace/adgroups',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns GetMarketplaceAdGroupCreativesQueryResult OK
     * @throws ApiError
     */
    public postApiMonetizationMarketplaceCreatives(
        requestBody?: GetMarketplaceAdGroupCreativesQuery,
    ): CancelablePromise<GetMarketplaceAdGroupCreativesQueryResult> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/monetization/marketplace/creatives',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param id
     * @param requestBody
     * @returns PXCommandResult OK
     * @throws ApiError
     */
    public putApiMonetizationMarketplaceAdgroupsStatus(
        id: number,
        requestBody?: ToggleMarketplaceAdGroupStatusCommand,
    ): CancelablePromise<PXCommandResult> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/monetization/marketplace/adgroups/{id}/status',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param id
     * @param creativeId
     * @param requestBody
     * @returns PXCommandResult OK
     * @throws ApiError
     */
    public putApiMonetizationMarketplaceAdGroupsCreativesStatus(
        id: number,
        creativeId: number,
        requestBody?: ToggleMarketplaceAdGroupCreativeStatusCommand,
    ): CancelablePromise<PXCommandResult> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/monetization/marketplace/adGroups/{id}/creatives/{creativeId}/status',
            path: {
                'id': id,
                'creativeId': creativeId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
