/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum DemandMediaType {
    NONE = 'none',
    ENDEMIC_MARKETPLACE = 'endemic-marketplace',
    DIRECT_SOLD = 'direct-sold',
    DTC_ENDEMIC = 'dtc-endemic',
    DTC_PROGRAMMATIC = 'dtc-programmatic',
}
