/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AddMonetizationPlanCommand } from '../models/AddMonetizationPlanCommand';
import type { GetAllMonetizationPlansQuery } from '../models/GetAllMonetizationPlansQuery';
import type { GetAllMonetizationPlansQueryResult } from '../models/GetAllMonetizationPlansQueryResult';
import type { MonetizationPlanDto } from '../models/MonetizationPlanDto';
import type { PXCommandResult } from '../models/PXCommandResult';
import type { UpdateMonetizationPlanCommand } from '../models/UpdateMonetizationPlanCommand';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class MonetizationPlansService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * @param marketerId
     * @param requestBody
     * @returns GetAllMonetizationPlansQueryResult OK
     * @throws ApiError
     */
    public postApiMonetizationPlansMarketer(
        marketerId: number,
        requestBody?: GetAllMonetizationPlansQuery,
    ): CancelablePromise<GetAllMonetizationPlansQueryResult> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/Monetization/Plans/marketer/{marketerId}',
            path: {
                'marketerId': marketerId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param planId
     * @returns MonetizationPlanDto OK
     * @throws ApiError
     */
    public getApiMonetizationPlans(
        planId: number,
    ): CancelablePromise<MonetizationPlanDto> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/Monetization/Plans/{PlanId}',
            path: {
                'PlanId': planId,
            },
        });
    }
    /**
     * @param requestBody
     * @returns PXCommandResult OK
     * @throws ApiError
     */
    public postApiMonetizationPlans(
        requestBody?: AddMonetizationPlanCommand,
    ): CancelablePromise<PXCommandResult> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/Monetization/Plans',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param id
     * @param requestBody
     * @returns PXCommandResult OK
     * @throws ApiError
     */
    public putApiMonetizationPlans(
        id: number,
        requestBody?: UpdateMonetizationPlanCommand,
    ): CancelablePromise<PXCommandResult> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/Monetization/Plans/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param id
     * @returns PXCommandResult OK
     * @throws ApiError
     */
    public deleteApiMonetizationPlans(
        id: number,
    ): CancelablePromise<PXCommandResult> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/Monetization/Plans/{id}',
            path: {
                'id': id,
            },
        });
    }
}
