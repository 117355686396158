/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AddPldOnlyCreativeCommand } from '../models/AddPldOnlyCreativeCommand';
import type { AddPldOnlyCreativeCommandResult } from '../models/AddPldOnlyCreativeCommandResult';
import type { GetAdGroupsListQuery } from '../models/GetAdGroupsListQuery';
import type { GetAdGroupsListQueryResult } from '../models/GetAdGroupsListQueryResult';
import type { GetPldAdGroupCreativeQueryResult } from '../models/GetPldAdGroupCreativeQueryResult';
import type { PXCommandResult } from '../models/PXCommandResult';
import type { UpdateAdGroupPublisherInventoryCommand } from '../models/UpdateAdGroupPublisherInventoryCommand';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class DirectSoldAdGroupsService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * @param requestBody
     * @returns GetAdGroupsListQueryResult OK
     * @throws ApiError
     */
    public postApiMonetizationDirectsoldAdgroups(
        requestBody?: GetAdGroupsListQuery,
    ): CancelablePromise<GetAdGroupsListQueryResult> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/monetization/directsold/adgroups',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param id
     * @returns PXCommandResult OK
     * @throws ApiError
     */
    public putApiMonetizationDirectsoldAdgroupsFavorite(
        id: number,
    ): CancelablePromise<PXCommandResult> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/monetization/directsold/adgroups/{id}/favorite',
            path: {
                'id': id,
            },
        });
    }
    /**
     * @param id
     * @param requestBody
     * @returns PXCommandResult OK
     * @throws ApiError
     */
    public putApiMonetizationDirectsoldAdgroupsInventory(
        id: number,
        requestBody?: UpdateAdGroupPublisherInventoryCommand,
    ): CancelablePromise<PXCommandResult> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/monetization/directsold/adgroups/{id}/inventory',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param id
     * @returns GetPldAdGroupCreativeQueryResult OK
     * @throws ApiError
     */
    public getApiMonetizationDirectsoldAdgroupsCreative(
        id: number,
    ): CancelablePromise<GetPldAdGroupCreativeQueryResult> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/monetization/directsold/adgroups/{id}/creative',
            path: {
                'id': id,
            },
        });
    }
    /**
     * @param id
     * @param requestBody
     * @returns AddPldOnlyCreativeCommandResult OK
     * @throws ApiError
     */
    public postApiMonetizationDirectsoldAdgroupsCreative(
        id: number,
        requestBody?: AddPldOnlyCreativeCommand,
    ): CancelablePromise<AddPldOnlyCreativeCommandResult> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/monetization/directsold/adgroups/{id}/creative',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param id
     * @returns binary OK
     * @throws ApiError
     */
    public getApiMonetizationDirectsoldAdgroupsCreativeCsv(
        id: number,
    ): CancelablePromise<Blob> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/monetization/directsold/adgroups/{id}/creative/csv',
            path: {
                'id': id,
            },
        });
    }
}
