/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GetAdGroupInventoryQueryResult } from '../models/GetAdGroupInventoryQueryResult';
import type { GetPublisherInventoryQuery } from '../models/GetPublisherInventoryQuery';
import type { PublisherInventoryDto } from '../models/PublisherInventoryDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class InventoryService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * @param marketerId
     * @param requestBody
     * @returns PublisherInventoryDto OK
     * @throws ApiError
     */
    public postApiMonetizationTargetingInventory(
        marketerId: number,
        requestBody?: GetPublisherInventoryQuery,
    ): CancelablePromise<Array<PublisherInventoryDto>> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/Monetization/targeting/inventory/{marketerId}',
            path: {
                'marketerId': marketerId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param id
     * @returns GetAdGroupInventoryQueryResult OK
     * @throws ApiError
     */
    public getApiMonetizationTargetingInventoryAdgroup(
        id: number,
    ): CancelablePromise<GetAdGroupInventoryQueryResult> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/Monetization/targeting/inventory/adgroup/{id}',
            path: {
                'id': id,
            },
        });
    }
}
